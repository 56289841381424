import React from 'react';
import {Link} from 'gatsby';

function HeaderSection() {
  return (
    <section className="hire-python-dev__header-section">
      <div className="hire-python-dev__header-container">
        <div className="hire-python-dev__header-inner">
          <h1 className="hire-python-dev__header-title">
            Among the <span className="hire-python-dev__header-title--bold">Top 1%</span> of Python
            Development Companies
          </h1>
          <p className="hire-python-dev__header-description">
            With over 10+ years of experience as the go to growth partner for clients across the
            globe, Tintash is among the top 1% of software outsourcing companies and provides
            dedicated remote teams that work closely with you to design and build your digital
            products.
          </p>
          <Link
            to="/contact"
            className="hire-python-dev__button hire-python-dev__button--primary hire-python-dev__header-button"
          >
            Build Your Team Today
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HeaderSection;
