import React from 'react';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import '../../../assets/css/services/webdev-hire-python-developers.css';
import Layout from '../../../components/Shared/Layout';
import HeaderSection from '../../../components/Services/WebDevHirePythonDevelopers/HeaderSection';
import PythonTeamSection from '../../../components/Services/WebDevHirePythonDevelopers/PythonTeamSection';
import AboutPythonSection from '../../../components/Services/WebDevHirePythonDevelopers/AboutPythonSection';
import HireProcessSection from '../../../components/Services/WebDevHirePythonDevelopers/HireProcessSection';
import ClientsLogoSection from '../../../components/Services/WebDevHirePythonDevelopers/ClientLogoSection';
import OurSolutionsSection from '../../../components/Services/WebDevHirePythonDevelopers/OurSolutionsSection';
import PythonApplicationsSection from '../../../components/Services/WebDevHirePythonDevelopers/PythonApplicationsSection';
import WhyChoosePythonSection from '../../../components/Services/WebDevHirePythonDevelopers/WhyChoosePythonSection';
import ContactUsSection from '../../../components/Services/WebDevHirePythonDevelopers/ContactUsSection';
import withUTMParams from '../../../components/HOC/withUTMParams';

function HirePythonDevelopers({location}) {
  React.useEffect(() => {
    ReactGA.set({
      title: 'Services/Web.Development/Hire.Python.Developers',
    });
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div className="webdev-hire-python__page">
      <Layout footerProps={{showPortfolioSection: false, showContactSection: false}}>
        <Helmet>
          <title>Industry Leading Python Development Company | Tintash</title>
          <meta
            name="description"
            content="Tintash is an Industry Leading Python Development Company, covering everything from  application development to hybrid programming employing Django and other frameworks, we have extensive experience in Python solutions. Hire your dedicated team of Python developers today."
          />
        </Helmet>
        <HeaderSection />
        <ClientsLogoSection />
        <PythonTeamSection />
        <AboutPythonSection />
        <HireProcessSection />
        <OurSolutionsSection />
        <WhyChoosePythonSection />
        <PythonApplicationsSection />
        <ContactUsSection />
      </Layout>
    </div>
  );
}

export default withUTMParams(HirePythonDevelopers);
