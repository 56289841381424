import React from 'react';
import WhyChoosePythonCard from './WhyChoosePythonCard';

const WHY_CHOOSE_PYTHON_DATA = [
  {
    icon: require('../../../assets/images/services/webdev-hire-python-developers/choose-python/simplicity.svg')
      .default,
    title: 'Simplicity',
    description:
      'Python’s straightforward and simple syntax is something that makes beginners want to learn this scripting language. From some perspective, it may seem natural and pre-determined that Python can turn into the lingua franca of coding, manifesting all the rest of its opponents obsolete. Its code is easy to comprehend, share, and maintain. There is no verbosity and the language is easy to learn.',
  },
  {
    icon: require('../../../assets/images/services/webdev-hire-python-developers/choose-python/powerful-toolbox.svg')
      .default,
    title: 'A Powerful Toolbox',
    description:
      'Python also has a huge array of third-party packages, libraries, and frameworks that facilitate the development process. These optimization capabilities thus make Python great for large-scale projects.',
  },
  {
    icon: require('../../../assets/images/services/webdev-hire-python-developers/choose-python/rapid-development.svg')
      .default,
    title: 'Rapid Development',
    description:
      'We mean business speed here and the time-to-market metric. Python is a dynamic scripting language, so it isn’t intended for writing applications from scratch but it’s primarily intended for plugging together components. Components are designed to be reusable while the interfaces between components and scripts are well-defined. It all accelerates the speed of software development with Python making the language highly concise and productive.',
  },
  {
    icon: require('../../../assets/images/services/webdev-hire-python-developers/choose-python/flexibility.svg')
      .default,
    title: 'Flexibility',
    description:
      'Although Python puts emphasis on code simplicity and readability rather than flexibility, the language still has it. Python is usable across different projects. It allows developers to choose between object-oriented and procedural programming modes.',
  },
  {
    icon: require('../../../assets/images/services/webdev-hire-python-developers/choose-python/portability.svg')
      .default,
    title: 'Portability',
    description:
      'Python is designed to be portable. Its programs are supported on any modern computer OS. Owing to the high-level nature of the language, Python script is interpreted, so it can be written for further interpretation equally well on Linux, Windows, Mac OS, and UNIX without demanding for adjustments. Python programs also allow implementing portable GUIs.',
  },
  {
    icon: require('../../../assets/images/services/webdev-hire-python-developers/choose-python/community.svg')
      .default,
    title: 'A Strong Community',
    description:
      'Python has a rapidly growing user base and actually is representative of what a strong community is. There are thousands of contributors to Python’s powerful toolbox — Pythonists. There are already almost 200,000 custom-built software packages user-uploaded to an online repository. All it implies that the great supportive community is both the reason for and the consequence of the language’s being in demand.',
  },
];

function WhyChoosePythonSection() {
  return (
    <section className="hire-python-dev__why-python-section">
      <h2 className="hire-python-dev__why-python-title">Why Companies Keep Choosing Python</h2>
      <p className="hire-python-dev__why-python-description">
        Some of the biggest companies in the world use Python, with good reason to do so. It is
        taken advantage of by Pixar to produce films, by Google to crawl pages, by Netflix to
        deliver content, and by Spotify to recommend songs. The language is full of benefits and
        there are some good reasons to love it:
      </p>
      <div className="hire-python-dev__why-python-cards">
        {WHY_CHOOSE_PYTHON_DATA.map((props, i) => (
          <WhyChoosePythonCard
            key={i}
            description={props.description}
            icon={props.icon}
            title={props.title}
          />
        ))}
      </div>
    </section>
  );
}

export default WhyChoosePythonSection;
