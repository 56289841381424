import {Link} from 'gatsby';
import React from 'react';

function ContactUsSection() {
  return (
    <div className="hire-python-dev__contact-section">
      <div className="hire-python-dev__contact-container">
        <p className="hire-python-dev__contact-title">We have worked with some great clients!</p>
        <Link
          to="/contact"
          className="hire-python-dev__button hire-python-dev__button--big hire-python-dev__button--secondary-invert"
        >
          Start Your Project
        </Link>
      </div>
    </div>
  );
}

export default ContactUsSection;
