import React from 'react';
import {Link} from 'gatsby';
import {ENGAGEMENT_MODELS_DATA} from '../../Home/engagement_models';

function OurSolutionsSection() {
  return (
    <section className="hire-python-dev__solutions-section">
      <div className="hire-python-dev__solutions-container">
        <h2 className="hire-python-dev__solutions-title">Our Solutions</h2>
        <p className="hire-python-dev__solutions-description">
          From fully managed teams to individual expert engineers, we offer tailored-fit Python
          development solutions that match your particular context and goals.
        </p>
        <div className="hire-python-dev__solutions-cards">
          {ENGAGEMENT_MODELS_DATA.cards.map((card, i) => (
            <div key={i} className="hire-python-dev__solutions-card">
              <h3 className="hire-python-dev__solutions-card-title">{card.title}</h3>
              <h4 className="hire-python-dev__solutions-card-sub-heading">Key Benefits</h4>
              <ul className="hire-python-dev__solutions-card-ul">
                {card.benefits.map(({label}, i) => (
                  <li key={i} className="hire-python-dev__solutions-card-li">
                    {label}
                  </li>
                ))}
              </ul>
              <h4 className="hire-python-dev__solutions-card-sub-heading">Suitable For</h4>
              <ul className="hire-python-dev__solutions-card-ul">
                {card.suitableFor.map((label, i) => (
                  <li key={i} className="hire-python-dev__solutions-card-li">
                    {label}
                  </li>
                ))}
              </ul>
              <div className="hire-python-dev__solutions-card-button-container">
                <Link
                  to="/contact"
                  className="w-100 hire-python-dev__button hire-python-dev__button--secondary hire-python-dev__solutions-card-button"
                >
                  Let’s Get Started
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OurSolutionsSection;
