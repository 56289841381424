import React from 'react';
import {Link,useStaticQuery, graphql} from 'gatsby';

import Img from 'gatsby-image';

function PythonTeamSection() {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: {eq: "services/webdev-hire-python-developers/artwork.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 310, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <section className="hire-python-dev-team__container">
      <div className="hire-python-dev__team-content-container">
        <div className="hire-python-dev-team__card-title">
          Leverage the best Python Development Talent to Build Your Dream Team
        </div>
        <p className="hire-python-dev-team__card-description">
          We’ve worked tirelessly across our time in the industry to cultivate the best Python
          Development talent, ensuring that your projects are in good hands.
        </p>
        <p className="hire-python-dev-team__card-description">
          We work closely with you as a dedicated growth partner to ensure that your dream projects
          are in safe hands, no matter the size or complexity. From dedicated Python development
          outsourcing, to complete project management and development, our commitment to quality
          ensures that you can rest easy regardless of project size and complexity. And thanks to
          the amazing pool of Python developers that we’ve cultivated over the past 10+ years, we’re
          able to rapidly build the ideal custom teams that match your Python development needs.
        </p>
        <p className="hire-python-dev-team__card-description">
          From dedicated architects to seasoned veterans, our Python developers are a diverse team
          that have worked on projects of all sizes, for companies ranging from disruptive startups
          to large scale enterprises. Get in touch with one of our Python consultants and elevate
          your project today.
        </p>
        <Link
          to="/contact"
          className="hire-python-dev__button hire-python-dev__button--secondary hire-python-dev-team__cta-button"
        >
          Build Your Python Development Team Today
        </Link>
      </div>

      <div className="hire-python-dev__team-art-container">
        <div className="hire-python-dev__team-art-overlay--1" />
        <div className="hire-python-dev__team-art-overlay--2" />
        <div className="hire-python-dev__team-art-overlay--3" />
        <div className="hire-python-dev__team-artwork">
          <Img
            className="img-fluid"
            fluid={data.cover.childImageSharp.fluid}
            alt="Artwork"
            fadeIn={false}
            loading="eager"
          />
        </div>
      </div>
    </section>
  );
}

export default PythonTeamSection;
