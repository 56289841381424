import React from 'react';

function PythonApplicationCard({img, alt, title, description}) {
  return (
    <div className="hire-python-dev__python-apps-card">
      <div className="hire-python-dev__python-apps-card-img">
        <img src={img} alt={alt} />
      </div>
      <div className="hire-python-dev__python-apps-card--inner">
        <h3 className="hire-python-dev__python-apps-card-title">{title}</h3>
        <p className="hire-python-dev__python-apps-card-description">{description}</p>
      </div>
    </div>
  );
}

export default PythonApplicationCard;
