import React from 'react';

function WhyChoosePythonCard({icon, title, description}) {
  const [isExpanded, setExpanded] = React.useState(false);
  const handleExpandedClick = () => {
    setExpanded(isExpanded => !isExpanded);
  };
  return (
    <div className="hire-python-dev__why-python-card">
      <img
        className="hire-python-dev__why-python-card-icon"
        width="65"
        height="65"
        src={icon}
        alt={title}
      />
      <h3 className="hire-python-dev__why-python-card-title">{title}</h3>
      <p
        className={`hire-python-dev__why-python-card-description ${
          isExpanded ? 'hire-python-dev__why-python-card-description--full' : ''
        }`}
      >
        {description}
      </p>
      <button className="hire-python-dev__why-python-card-btn" onClick={handleExpandedClick}>
        Read more
      </button>
    </div>
  );
}

export default WhyChoosePythonCard;
