import React from 'react';
import PythonApplicationCard from './PythonApplicationCard';

const PYTHON_APPLICATIONS_DATA = [
  {
    title: 'Web Development',
    description:
      'Python can be used to make web-applications at a rapid rate because of the frameworks Python uses to create these applications. There is common-backend logic that goes into making these frameworks and a number of libraries that can help integrate protocols such as HTTPS, FTP, SSL etc. and even help in the processing of JSON, XML, E-Mail and so much more.',
    img: require('../../../assets/images/services/webdev-hire-python-developers/circle-count-1.png')
      .default,
  },
  {
    title: 'Game Development',
    description:
      'Python is also used in the development of interactive games. There are libraries such as PySoy which is a 3D game engine supporting Python 3, PyGame which provides functionality and a library for game development. Games such as Civilization-IV, Disney’s Toontown Online, Vega Strike etc. have been built using Python.',
    img: require('../../../assets/images/services/webdev-hire-python-developers/circle-count-2.png')
      .default,
  },
  {
    title: 'Machine Learning & Artificial Intelligence',
    description:
      'Machine Learning and Artificial Intelligence are the talks of the town as they yield the most promising applications for the future. We make the computer learn based on past experiences through the data stored or better yet, create algorithms which makes the computer learn by itself. Python has relevant libraries that exist already such as Pandas, Scikit-Learn, NumPy and so many more.',
    img: require('../../../assets/images/services/webdev-hire-python-developers/circle-count-3.png')
      .default,
  },
  {
    title: 'Data Science and Data Visualization',
    description:
      'Data is money if you know how to extract relevant information which can help you take calculated risks and increase profits. You study the data you have, perform operations and extract the information required. Libraries such as Pandas, NumPy help you in extracting information.',
    img: require('../../../assets/images/services/webdev-hire-python-developers/circle-count-4.png')
      .default,
  },
  {
    title: 'Web Scrapping Applications',
    description:
      'Python is a savior when it comes to pull a large amount of data from websites which can then be helpful in various real-world processes such as price comparison, job listings, research and development and much more.',
    img: require('../../../assets/images/services/webdev-hire-python-developers/circle-count-5.png')
      .default,
  },
  {
    title: 'Business Applications',
    description:
      'Business Applications are different than our normal applications covering domains such as e-commerce, ERP and many more. They require applications which are scalable, extensible and easily readable and Python provides us with all these features. Platforms such as Tryton is available to develop such business applications.',
    img: require('../../../assets/images/services/webdev-hire-python-developers/circle-count-6.png')
      .default,
  },
];

function PythonApplicationsSection() {
  return (
    <section className="hire-python-dev__python-apps-section">
      <h2 className="hire-python-dev__python-apps-heading">
        What Types of Applications Feature Python?
      </h2>
      <p className="hire-python-dev__python-apps-description">
        From fully managed teams to individual expert engineers, we offer tailored-fit Python
        development solutions that match your particular context and goals.
      </p>
      <div className="hire-python-dev__python-apps-cards">
        {PYTHON_APPLICATIONS_DATA.map((props, i) => (
          <PythonApplicationCard key={i} {...props} alt={`${props.title} - ${i + 1}`} />
        ))}
      </div>
    </section>
  );
}

export default PythonApplicationsSection;
