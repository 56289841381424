import React from 'react';
import {Link} from 'gatsby';

function AboutPythonSection() {
  return (
    <section className="hire-python-dev__about-python-section">
      <div className="hire-python-dev__about-python-container">
        <div className="hire-python-dev__about-python-logo-container">
          <img
            src={
              require('../../../assets/images/services/webdev-hire-python-developers/python-logo.png')
                .default
            }
            alt="Python Logo"
          />
        </div>
        <h2 className="hire-python-dev__about-python-title">What is Python?</h2>
        <p className="hire-python-dev__about-python-description">
          Python is a general-purpose, interpreted, and high-level programming language that focuses
          on code readability. It is largely used by professional programmers and developers across
          a variety of fields, including but not limited to Web Development and Machine Learning.
        </p>
        <p className="hire-python-dev__about-python-description">
          The widespread adoption and large user base associated with Python means that it’s used
          widely across the globe for dynamic applications. Python is extremely popular for its
          versatility and scope of applicability.
        </p>
        <p className="hire-python-dev__about-python-description">
          Due to its versatility, leading IT companies like Google, Spotify, Instagram, and Dropbox,
          also vouch for Python development over other languages. Even non-IT giants like NASA,
          Electronic Arts, and Disney are using Python environments.
        </p>
        <Link
          to="/contact"
          className="hire-python-dev__button hire-python-dev__button--light hire-python-dev__about-python-button"
        >
          Learn More About Python
        </Link>
      </div>
    </section>
  );
}

export default AboutPythonSection;
