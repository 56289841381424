import React from 'react';
import ClientLogoCard from '../WebDevelopment/New/ClientLogoCard';

function ClientsLogoSection() {
  return (
    <section className="hire-python-dev__client-logos-containter">
      <ClientLogoCard image="bbc-worldwide.png" alt="Client Logo - BBC" />
      <ClientLogoCard image="bedbathbeyond.png" alt="Client Logo - Bed Bath and Beyond" />
      <ClientLogoCard image="careem.png" alt="Client Logo - Careem" />
      <ClientLogoCard image="drafkings.png" alt="Client Logo - Draft Kings" />
      <ClientLogoCard image="raven.png" alt="Client Logo - Raven" />
    </section>
  );
}

export default ClientsLogoSection;
