import React from 'react';
import {Link} from 'gatsby';

function HireProcessSection() {
  return (
    <section className="hire-python-dev__tintash-hire-process-section">
      <div className="hire-python-dev__tintash-hire-process-container">
        <h2 className="hire-python-dev__tintash-hire-process-title">
          How to Hire Python Developers through Tintash
        </h2>
        <p className="hire-python-dev__tintash-hire-process-description">
          From fully managed teams to individual expert engineers, we offer tailored-fit Python
          development solutions that match your particular context and goals.
        </p>
        <div className="hire-python-dev__tintash-hire-process-cards">
          <div className="hire-python-dev__tintash-hire-process-card">
            <div className="hire-python-dev__tintash-hire-process-card-img">
              <img
                src={
                  require('../../../assets/images/services/webdev-hire-python-developers/circle-count-1.png')
                    .default
                }
                alt="One"
              />
            </div>
            <h3 className="hire-python-dev__tintash-hire-process-card-title">Tell us About You</h3>
            <p className="hire-python-dev__tintash-hire-process-card-description">
              Tell us about your company, your project, or the goals you want to achieve. Our senior
              Python consultants will talk it out with you.
            </p>
          </div>
          <div className="hire-python-dev__tintash-hire-process-card">
            <div className="hire-python-dev__tintash-hire-process-card-img">
              <img
                src={
                  require('../../../assets/images/services/webdev-hire-python-developers/circle-count-2.png')
                    .default
                }
                alt="Two"
              />
            </div>
            <h3 className="hire-python-dev__tintash-hire-process-card-title">
              Meet Your Custom Team
            </h3>
            <p className="hire-python-dev__tintash-hire-process-card-description">
              We will assign a dedicated Python development team to your project within days,
              letting them hit the ground running as they work with you to figure out your
              requirements.
            </p>
          </div>
          <div className="hire-python-dev__tintash-hire-process-card">
            <div className="hire-python-dev__tintash-hire-process-card-img">
              <img
                src={
                  require('../../../assets/images/services/webdev-hire-python-developers/circle-count-3.png')
                    .default
                }
                alt="Three"
              />
            </div>
            <h3 className="hire-python-dev__tintash-hire-process-card-title">
              Supercharge Your Project
            </h3>
            <p className="hire-python-dev__tintash-hire-process-card-description">
              Your custom team of Python developers will integrate seamlessly with your in-house
              operations and jump-start your project right away.
            </p>
          </div>
        </div>
        <Link
          to="/contact"
          className="mx-auto hire-python-dev__button hire-python-dev__button--secondary"
        >
          Build Your Python Development Team
        </Link>
      </div>
    </section>
  );
}

export default HireProcessSection;
