import React from 'react';
import {useLocation} from '@reach/router';
import qs from 'query-string';
import {CONTACT_UTM_SEARCH_PARAM_KEY} from '../../pages/contact';

const PARAM_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid', 'fbclid'];

function withUTMParams(WrappedComponent) {
  return props => {
    const {search} = useLocation();

    React.useEffect(() => {
      const existingParams = localStorage.getItem(CONTACT_UTM_SEARCH_PARAM_KEY);

      if (!existingParams) {
        const params = qs.stringify(qs.parse(qs.pick(search, PARAM_KEYS)), {
          skipEmptyString: true,
          skipNull: true,
        });

        console.log(params);
        if (params?.length) {
          localStorage.setItem(CONTACT_UTM_SEARCH_PARAM_KEY, params);
        }
      }
    }, []);

    return <WrappedComponent {...props} />;
  };
}

export default withUTMParams;
